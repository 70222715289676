export default {
    "HOMEPAGE_TITLE": "افتح إمكانيات الكسب: عزز عوائدك مع <span>{{StakingReview}}</span>",
    "TITLE_CONTENT": "ابدأ رحلتك في مجال العملات المشفرة اليوم وشاهد استثماراتك تزدهر. حان الوقت للمشاركة في ثورة العملات المشفرة!",
    "HOME_BTN1": "استثمر الآن",
    "HOME_BTN2": "سجل الآن",
    "COUNT1": "جميع المشاركين",
    "COUNT2": "إجمالي الأرباح",
    "COUNT3": "تغيير BTC خلال 24 ساعة",
    "HEADER1": "كيف يعمل <span>{{StakingReview}}</span>",
    "TITLE11": "استثمر عملتك المشفرة",
    "CONTENT11": "اختر عملة مشفرة تدعم الاستثمار، واحصل عليها، وانقلها إلى منصة أو محفظة تدعم الاستثمار.",
    "TITLE12": "شارك في Staking Review",
    "CONTENT12": "تساعد عملتك المشفرة المستثمرة في تأمين شبكة البلوكشين وتدعم عملياتها. تتيح لك المشاركة في أنشطة الشبكة الحصول على مكافآت استناداً إلى حجم استثمارك وقواعد الشبكة.",
    "TITLE13": "إدارة ومراقبة",
    "CONTENT13": "راجع أداء استثمارك بشكل منتظم، وتتبع مكافآتك، وابقَ على اطلاع بالتغييرات في الشبكة. قم بتعديل استراتيجيتك حسب الحاجة لتعظيم تجربة الاستثمار الخاصة بك.",
    "HEADER2": "اكسب حتى <span>{{StakingReview}}</span> من أصولك",
    "TITLE21": "دخل سلبي مع الاستثمار",
    "CONTENT21": "استكشاف إمكانيات الربح السلبي من خلال استثمار العملات المشفرة",
    "TITLE22": "تعظيم عوائد الاستثمار",
    "CONTENT22": "استراتيجيات لزيادة أرباحك في استثمار العملات المشفرة",
    "TITLE23": "فهم مكافآت الاستثمار",
    "CONTENT23": "كيف يؤدي الاستثمار إلى أرباح في عالم العملات المشفرة",
    "STAKECAL_TITLE": "شاهد ما يمكنك كسبه <span>{{Staking}}</span>",
    "ASSET": "الأصل",
    "AMOUNT": "المبلغ",
    "AMOUNT_PLACEHOLDER": "أدخل المبلغ",
    "STAKING_METHOD": "طريقة الاستثمار",
    "DEPOSIT_TERM": "مدة الإيداع",
    "FLEXIBLE": "مرن",
    "FIXED": "ثابت",
    "STAKE_PERIOD": "في 12 شهرًا سيكون لديك إجمالي",
    "IN": "في",
    "YOU_WILL": "سيكون لديك إجمالي",
    "FREQUENTLY_ASKED_QUESTIONS": "الأسئلة المتكررة <span>{{Questions}}</span>",
    "REGISTER": "سجل",
    "LOGIN": "تسجيل الدخول",
    "SIGN_UP": "اشتراك",
    "SIGN_IN": "تسجيل الدخول",
    "FIRST_NAME": "الاسم الأول",
    "FIRST_NAME_PLACEHOLDER": "أدخل الاسم الأول",
    "FIRST_NAME_REQUIRED": "حقل الاسم الأول مطلوب",
    "LAST_NAME": "الاسم الأخير",
    "LAST_NAME_PLACEHOLDER": "أدخل الاسم الأخير",
    "LAST_NAME_REQUIRED": "حقل الاسم الأخير مطلوب",
    "EMAIL": "عنوان البريد الإلكتروني",
    "EMAIL_PLACEHOLDER": "أدخل عنوان البريد الإلكتروني",
    "EMAIL_REQUIRED": "حقل البريد الإلكتروني مطلوب",
    "EMAIL_INVALID": "بريد إلكتروني غير صالح",
    "EMAIL_NOT_EXISTS": "البريد الإلكتروني غير موجود",
    "EMAIL_EXISTS": "البريد الإلكتروني موجود بالفعل",
    "PHONE_NUMBER": "رقم الهاتف",
    "PHONE_PLACEHOLDER": "أدخل رقم الهاتف",
    "PHONE_NUMBER_REQUIRED": "حقل رقم الهاتف مطلوب",
    "PHONE_NUMBER_INVALID": "رقم الهاتف غير صالح",
    "PHONE_NUMBER_EXISTS": "رقم الهاتف موجود بالفعل",
    "PASSWORD": "كلمة المرور",
    "PASSWORD_PLACEHOLDER": "أدخل كلمة المرور",
    "PASSWORD_REQUIRED": "حقل كلمة المرور مطلوب",
    "PASSWORD_INCORRECT": "كلمة المرور غير صحيحة",
    "PASSWORD_MIN_MAX": "يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل، حرف صغير واحد على الأقل، رقم واحد على الأقل، حرف خاص واحد على الأقل، ويجب أن تكون من 6 إلى 18 حرفًا كحد أقصى.",
    "CONFIRM_PASSWORD": "تأكيد كلمة المرور",
    "CONFIRM_PASSWORD_PLACEHOLDER": "أدخل تأكيد كلمة المرور",
    "CONFIRM_PASSWORD_REQUIRED": "حقل تأكيد كلمة المرور مطلوب",
    "CONFIRM_PASSWORD_MISMATCH": "يجب أن تكون كلمة المرور وتأكيد كلمة المرور متطابقتين",
    "TERMS_REQUIRED": "حقل الشروط والسياسات مطلوب",
    "ALREADY_HAVE_ACCOUNT": "لديك حساب بالفعل",
    "DON'T_HAVE_ACCOUNT": "ليس لديك حساب",
    "REGISTER_CHECKBOX": "أفهم <Link to='/terms'>{{value1}}</Link> و <Link1 to='/privacy'>{{value2}}</Link1>",
    "OTP": "رمز التحقق",
    "OTP_PLACEHOLDER": "أدخل رمز التحقق",
    "RESENT_OTP": "إعادة إرسال رمز التحقق",
    "OTP_VALIDUPTO": "صالح حتى",
    "OTP_REQUIRED": "حقل رمز التحقق مطلوب",
    "OTPSTATUS_REQUIRED": "حالة رمز التحقق مطلوبة",
    "INVALID_OTPSTATUS": "حالة رمز التحقق غير صالحة",
    "OTP_SENT_SUCCESS": "تم إرسال رمز التحقق إلى بريدك الإلكتروني، يرجى إدخال رمز التحقق",
    "EXPIRED_OTP": "رمز التحقق منتهي الصلاحية",
    "INVALID_OTP": "رمز التحقق غير صالح",
    "SEC": "ثانية",
    "REGISTER_SUCCESS": "تم تسجيل حسابك بنجاح. شكرًا لك!",
    "SOMETHING_WRONG": "حدث خطأ ما",
    "ERROR_ON_SERVER": "خطأ في الخادم",
    "ERROR_OCCURED": "حدث خطأ",
    "EMAIL_UNVERIFIED": "حسابك لم يتم تفعيله بعد",
    "ACCOUNT_DEACTIVATE": "تم إلغاء تنشيط حسابك من قبل المسؤول، يرجى الاتصال بالمشرف",
    "LOGIN_SUCCESS": "تم تسجيل الدخول بنجاح",
    "LOGOUT_SUCCESS": "تم تسجيل الخروج بنجاح!",
    "FORGOT_PASSWORD": "نسيت كلمة المرور",
    "FORGOT_PASSWORD_CONTENT": "تم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني المسجل",
    "FORGOT_SUCCESS": "تم إرسال رابط التأكيد إلى بريدك المسجل",
    "AUTH_TOKEN_REQUIRED": "حقل رمز المصادقة مطلوب",
    "INVALID_TOKEN": "رمز المصادقة غير صالح",
    "RESET_PASSWORD": "إعادة تعيين كلمة المرور",
    "NEW_PASSWORD": "كلمة المرور الجديدة",
    "NEW_PASSWORD_PLACEHOLDER": "أدخل كلمة المرور الجديدة",
    "NEW_PASSWORD_REQUIRED": "حقل كلمة المرور الجديدة مطلوب",
    "NEW_CONFIRM_MISMATCH": "يجب أن تكون كلمة المرور الجديدة وتأكيدها متطابقتين",
    "LING_EXPIRED": "الرابط الخاص بك منتهي الصلاحية",
    "INVALID_LINK": "الرابط غير صالح",
    "USER_NOT_FOUND": "المستخدم غير موجود",
    "VERIFICATION_SUCCESS": "اكتمل التحقق الخاص بك، يمكنك الآن تغيير كلمة المرور",
    "RESET_PASSWORD_SUCCESS": "تم تحديث كلمة المرور بنجاح",
    "CHANGE_PASSWORD": "تغيير كلمة المرور",
    "OLD_PASSWORD": "كلمة المرور القديمة",
    "OLD_PASSWORD_PLACEHOLDER": "أدخل كلمة المرور القديمة",
    "OLD_PASSWORD_REQUIRED": "حقل كلمة المرور القديمة مطلوب",
    "INCORRECT_OLDPASSWORD": "كلمة المرور القديمة غير صحيحة",
    "PASSWORD_SAME": "كلمة المرور الجديدة لا يمكن أن تكون هي نفسها القديمة",
    "CHANGE_PASSWORD_SUCCESS": "تم تغيير كلمة المرور بنجاح",
    "CHANGE": "تغيير",
    "LAST_LOGIN": "آخر تسجيل دخول",
    "EDIT_PROFILE": "تعديل الملف الشخصي",
    "UPDATE_PROFILE": "تحديث الملف الشخصي",
    "PROFILE": "صورة الملف الشخصي (اختياري)",
    "INVALID_PROFILE": "تنسيق الملف الشخصي غير صالح",
    "TOO_LARGE": "الحجم كبير جدًا",
    "PROFILE_RESTRICTION": "الحد الأقصى لحجم تحميل الملف 2 ميجا بايت (Jpeg, Png, Jpg فقط).",
    "ADDRESS": "العنوان",
    "ADDRESS_PLACEHOLDER": "أدخل العنوان",
    "ADDRESS_REQUIRED": "حقل العنوان مطلوب",
    "CITY": "المدينة",
    "CITY_PLACEHOLDER": "أدخل المدينة",
    "CITY_REQUIRED": "حقل المدينة مطلوب",
    "POSTAL_CODE": "الرمز البريدي",
    "POSTAL_CODE_PLACEHOLDER": "أدخل الرمز البريدي",
    "POSTAL_CODE_REQUIRED": "حقل الرمز البريدي مطلوب",
    "COUNTRY": "الدولة",
    "COUNTRY_PLACEHOLDER": "أدخل الدولة",
    "COUNTRY_REQUIRED": "حقل الدولة مطلوب",
    "UPLOAD_IMAGE": "لا يمكن أن يتجاوز حجم الصورة 2 ميجا بايت!",
    "CANCEL": "إلغاء",
    "UPDATE": "تحديث",
    "SUBMIT": "إرسال",
    "PROFILE_SUCCESS": "تم تحديث الملف الشخصي بنجاح",
    "LOGIN_ACTIVITY": "نشاط تسجيل الدخول",
    "DATE": "التاريخ",
    "BROWSER": "المتصفح",
    "STATUS": "الحالة",
    "FAILED": "فشل",
    "SUCCESS": "نجاح",
    "MY_ACCOUNT": "حسابي",
    "ACCOUNT_SETTINGS": "إعدادات الحساب",
    "LOGOUT": "تسجيل الخروج",
    "NOTIFICATION": "الإشعارات",
    "NO_NOTIFICATION": "لا توجد إشعارات!",
    "UPDATE_SUCCESS": "تم التحديث بنجاح",


    "SUPPORT_TICKET": "تذكرة الدعم",
    "SUBMIT_REQUEST": "إرسال طلب",
    "TICKET_FOR": "التذكرة ل",
    "SELECT_CATEGORY": "اختر الفئة",
    "DESCRIPTION": "الوصف",
    "ENTER_MESSAGE": "أدخل الرسالة",
    "SUPPORT_DESCRIPTION": "يرجى إدخال تفاصيل طلبك. سيرد أحد أعضاء فريق الدعم في أقرب وقت ممكن.",
    "REQUIRED": "مطلوب",
    "DOCUMENT_UPLOAD": "تحميل مستند (اختياري)",
    "UPLOAD": "تحميل",
    "UPLOAD_SIZE_RESTRICT": "الحد الأقصى لحجم الملف 1 ميجابايت (Jpeg, Png, Pdf, Jpg فقط).",
    "UPLOAD_ERROR": "لا يمكن تحميل الملف بحجم أكبر من 1 ميجابايت!",
    "MESSAGE_REQUIRED": "حقل الرسالة مطلوب",
    "SUBJECT_REQUIRED": "حقل الموضوع مطلوب",
    "UPLOAD_ERROR2": "يرجى اختيار أنواع الملفات المعروفة: jpg, jpeg, png, pdf، أو mp4.",
    "TICKET_RAISED_SUCCESS": "تم رفع تذكرة الدعم بنجاح",
    "TICKET_ALREADY_CLOSED": "تم إغلاق التذكرة بالفعل",
    "MESSAGE_SENT_SUCCESS": "تم إرسال رسالتك بنجاح",
    "SUPPORT_TICKET_DETAILS": "تفاصيل تذكرة الدعم",
    "TICKET_CLOSED_SUCCESS": "تم إغلاق التذكرة بنجاح",
    "TICKET_ID": "معرف التذكرة",
    "CREATES_ON": "تم الإنشاء في",
    "VIEW": "عرض الملف",
    "SUPPORT_TEAM": "فريق الدعم",
    "OPEN": "مفتوح",
    "CLOSED": "مغلق",
    "ALL": "الكل",
    "SEARCH": "بحث",
    "CLOSE_TICKET": "إغلاق التذكرة",
    "SUB": "العنوان الفرعي",
    "TICKET_NOT_FOUND": "لم يتم العثور على تذاكر",

    "WALLET": "محفظة",
    "TOTAL_BALANCE": "الرصيد الإجمالي",
    "BALANCE": "الرصيد",
    "ACTION": "الإجراء",
    "DEPOSIT": "إيداع",
    "WITHDRAW": "سحب",
    "EXCHANGE": "تبادل",
    "EXCHANGE_ACCESS": "تم حظر الوصول إلى التبادل",
    "STAKE": "الرهان",
    "STAKE_ACCESS": "تم حظر الوصول إلى الرهان",
    "TRADE_ACCESS": "تم حظر الوصول إلى التداول",
    "LIVE_EVENT_ACCESS": "تم حظر الوصول إلى الأحداث المباشرة",
    "HEDGE_ACCESS": "تم حظر الوصول إلى صندوق التحوط",
    "HIDE_ZERO_BALANCES": "إخفاء الأرصدة الصفرية",



    "DEPOSIT_TERMS": "شروط الإيداع",
    "MINIMUM_DEPOSIT_AMOUNT": "الحد الأدنى لمبلغ الإيداع: ",
    "INVALID_IMAGE": "صورة غير صالحة",
    "MAINTENANCE_ERROR": "الصيانة جارية، حاول مرة أخرى لاحقاً!",
    "DEPOSIT_DESCRIPTION": "الإيداعات التي تقل عن الحد الأدنى لن يتم قبولها ولا يُسمح بالاسترداد",
    "DEPOSIT_NOTES1": "أرسل فقط {{CURRENCY_SYMBOL}} إلى عنوان المحفظة هذا.",
    "DEPOSIT_NOTES2": "قد يؤدي إرسال عملات أو رموز غير {{CURRENCY_SYMBOL}} إلى هذا العنوان إلى خسارة كاملة للإيداع",
    "DEPOSIT_NOTES3": "يرجى تحميل لقطة شاشة أو معرّف التجزئة لمعاملتك لكي نتمكن من الموافقة على معاملتك",
    "DEPOSIT_CARD": "الإيداع باستخدام بطاقة",
    "DEPOSIT_ADDRESS": "عنوان الإيداع",
    "MEMO_ADDRESS": "عنوان المذكرة",
    "DEPOSIT_IMAGE_RESTRICTION": "أقصى حجم تحميل للملف 2MB (Jpg, Jpeg, Png, Pdf فقط).",
    "DEPOSIT_AMOUNT_ERROR": "يرجى إدخال مبلغ الإيداع الذي يساوي أو يزيد عن {{AMOUNT}}",
    "DEPOSIT_SUCCESS": "تم إرسال طلب الإيداع بنجاح",
    "ASSET_ERROR": "حقل أصل المستخدم مطلوب",
    "COPIED": "تم النسخ!",


    "WITHDRAW_TERMS": "شروط السحب",
    "MINIMUM_WITHDRAW_AMOUNT": "الحد الأدنى لمبلغ السحب هو: ",
    "TERMS1": "الحد الأقصى للسحب: ",
    "TERMS2": "رسوم السحب: ",
    "RECEIVER_ADDRESS": "عنوان المستلم",
    "RECEIVER_ADDRESS_PLACEHOLDER": "يرجى إدخال عنوان السحب هنا",
    "RECEIVER_ADDRESS_REQUIRED": "حقل عنوان المستلم مطلوب",
    "WITHDRAW_AMOUNT": "مبلغ السحب",
    "WITHDRAW_AMOUNT_ERROR": "يرجى إدخال مبلغ سحب يساوي أو يزيد عن {{AMOUNT}}",
    "MINIMUM_WITHDRAW_ERROR": "يجب أن يكون الحد الأدنى لمبلغ السحب يساوي أو يزيد عن {{AMOUNT}}",
    "WITHDRAW_MAXAMOUNT_ERROR": "يجب أن يكون مبلغ السحب أقل أو يساوي {{AMOUNT}}",
    "WITHDRAW_SUCCESS": "تم إرسال طلب السحب بنجاح",
    "INSUFFICIENT_WITHDRAW": "مبلغ السحب منخفض للغاية",
    "WITHDRAW_OTP": "تم إرسال رمز التحقق (OTP) بنجاح إلى بريدك الإلكتروني. يرجى التحقق من صندوق الوارد واستخدام الرمز المقدم للمتابعة",

    "FROM_CURRENCY": "من العملة",
    "TO_CURRENCY": "إلى العملة",
    "TO_CURRENCY_REQUIRED": "حقل العملة المستلمة مطلوب",
    "SELECT_CURRENCY": "اختر العملة",
    "INVALID_USER": "مستخدم غير صالح",
    "INSUFFICIENT_BALANCE": "رصيد غير كافٍ",
    "TRANSFER_SUCCESS": "تم التحويل بنجاح",

    "TRANSACTION": "المعاملات",
    "DATE": "التاريخ",
    "COIN": "العملة",
    "USER_ID": "معرف المستخدم",
    "TYPE": "النوع",
    "REASON": "السبب",
    "PENDING": "قيد الانتظار",
    "COMPLETED": "مكتمل",
    "REJECTED": "مرفوض",
    "COIN_DEPOSIT": "إيداع العملة",
    "COIN_WITHDRAW": "سحب العملة",
    "COIN_TRANSFER": "تحويل العملة",
    "PROFIT": "الربح",
    "ROWS_PER_PAGE": "عدد الصفوف لكل صفحة",
    "OF": "من",



    "STAKING": "التخزين",
    "AVAILABLE_BALANCE": "الرصيد المتاح",
    "AVAILABLE_BALANCE_USDT": "الرصيد المتاح USDT",
    "APY": "نسبة العائد السنوي",
    "MAX": "أقصى",
    "INTEREST": "الفائدة",
    "MINIMUM_STAKE": "أدنى مبلغ للتخزين",
    "MAXIMUM_STAKE": "أقصى مبلغ للتخزين",
    "WITHDRAWAL_TERM": "مدة السحب",
    "REDEMPTION_PERIOD": "فترة الاسترداد",
    "DURATION": "المدة (أيام)",
    "CONFIRM": "تأكيد",
    "STAKING_PERIOD": "فترة التخزين",
    "LOCKED_FOR": "مقفل لمدة",
    "AMOUNT_REQUIRED": "حقل المبلغ مطلوب",
    "VALID_AMOUNT": "يرجى إدخال مبلغ صالح",
    "AMOUNT_CAN'T_ZERO": "يجب أن يكون المبلغ أكبر من الصفر",
    "MINIMUM_AMOUNT": "يجب أن يكون المبلغ الأدنى أكبر من أو يساوي {{AMOUNT}}",
    "MAXIMUM_AMOUNT": "يجب أن يكون المبلغ أقل من أو يساوي {{AMOUNT}}",
    "INVALID_STAKING": "تخزين غير صالح",
    "INVALID_CURRENCY": "عملة غير صالحة",
    "INVALID_ASSET": "أصل غير صالح",
    "DEACTIVE": "غير نشط",
    "NO_RECORD": "لا يوجد سجل",
    "INSUFFICIENT_ASSET": "لا يوجد ما يكفي من الأصول في رصيدك",
    "STAKING_SUCCESS": "تمت إضافة طلب التخزين بنجاح",
    "DURATION_ERROR": "يرجى اختيار مدة الأيام",
    "ALREADY_CANCELED": "تم إلغاؤه بالفعل",
    "STAKING_HISTORY": "تاريخ التخزين",
    "TOTAL_EARNED_REDEMPTION": "إجمالي الاسترداد المكتسب",
    "INTEREST_PAID": "الفائدة المدفوعة",
    "ESTIMATED_APY_INTEREST": "الفائدة المقدرة بنسبة العائد السنوي",
    "ESTIMATED_TOTAL_INTEREST": "إجمالي الفائدة المقدرة",
    "ESTIMATED_TOTAL_REDEMPTION": "إجمالي الاسترداد المقدّر",
    "REMAINING_DAYS": "الأيام المتبقية",
    "RELEASE": "إصدار",
    "INTEREST_EARNED": "الفائدة المكتسبة",
    "INTEREST_IN": "الفائدة بالـ ($)",
    "STAKE_TYPE": "نوع التخزين",
    "SUBSCRIPTION": "الاشتراك",
    "REDEMPTION_HISTORY": "تاريخ الاسترداد",
    "THERE_NO_RECORD": "لا توجد سجلات لعرضها",
    "RELEASE_STAKE": "هل أنت متأكد من إلغاء هذا الطلب؟",
    "OK": "موافق",
    "RELEASE_SUCCESS": "تم الإصدار بنجاح",



    "TRADE": "تداول",
    "PRO_TRADE": "تداول احترافي",
    "BASIC": "أساسي",
    "LOADING": "جارٍ التحميل",
    "PNL": "الأرباح والخسائر",
    "CASH_BALANCE": "رصيد نقدي",
    "EQUITY": "الأسهم",
    "USED_MARGIN": "الهامش المستخدم",
    "AVAILABLE_MARGIN": "الهامش المتاح",
    "CRYPTO": "عملات مشفرة",
    "CURRENCIES": "عملات",
    "STOCKS": "أسهم",
    "INDICES": "مؤشرات",
    "COMMODITIES": "سلع",
    "BUY": "شراء",
    "SELL": "بيع",
    "QUANTITY": "الكمية",
    "QUANTITY_PLACEHOLDER": "أدخل الكمية",
    "QUANTITY_REQUIRED": "حقل الكمية مطلوب",
    "QUANTITY_ERROR": "الكمية يجب أن تكون قيمة عددية فقط",
    "QUANTITY_GRATER": "يجب أن تكون الكمية أكبر من الصفر",
    "SIDE_REQUIRED": "حقل الجانب مطلوب",
    "INVALID_SIDE": "جانب غير صالح",
    "PAIR_REQUIRED": "حقل الزوج مطلوب",
    "TRADE_SIZE": "حجم التداول",
    "PIP_VALUE": "قيمة النقطة",
    "ORDER_BOOK": "سجل الطلبات",
    "OPEN_POSITIONS": "المراكز المفتوحة",
    "CLOSED_POSITIONS": "المراكز المغلقة",
    "CLOSE_ALL_TRADES": "إغلاق جميع الصفقات",
    "PAIR_NAME": "اسم الزوج",
    "BUYSELL": "شراء/بيع",
    "ENTRY_PRICE": "سعر الدخول",
    "OPEN_TIME": "وقت الفتح",
    "CLOSE_PRICE": "سعر الإغلاق",
    "CLOSE_TIME": "وقت الإغلاق",
    "CURRENT_PRICE": "السعر الحالي",
    "LEVERAGE": "الرافعة المالية",
    "PNL_CLOSE": "الأرباح والخسائر/إغلاق",
    "TP": "TP",
    "TAKE_PROFIT": "أخذ الربح",
    "STOP_LOSS": "وقف الخسارة",
    "SL": "SL",
    "TP_PRICE": "أخذ الربح عند السعر",
    "SP_PRICE": "وقف الخسارة عند السعر",
    "TAKE_PROFIT_PLACEHOLDER": "أدخل أخذ الربح",
    "STOP_LOSS_PLACEHOLDER": "أدخل وقف الخسارة",
    "TAKE_PROFIT_REQUIRED": "حقل أخذ الربح مطلوب",
    "TAKE_PROFIT_GREATER": "يجب أن يكون أخذ الربح أكبر من السعر السوقي",
    "TAKE_PROFIT_LESSER": "يجب أن يكون أخذ الربح أقل من السعر السوقي",
    "STOP_LOSS_REQUIRED": "حقل وقف الخسارة مطلوب",
    "STOP_LOSS_GREATER": "يجب أن يكون وقف الخسارة أقل من السعر السوقي",
    "STOP_LOSS_LESSER": "يجب أن يكون وقف الخسارة أكبر من السعر السوقي",
    "POSITION_NOT_FOUND": "لم يتم العثور على المركز",
    "TAKE_PROFIT_SUCCESS": "تم تحديث أخذ الربح بنجاح",
    "STOP_LOSS_SUCCESS": "تم تحديث وقف الخسارة بنجاح",
    "CLOSED_USER": "مغلق بواسطة المستخدم",
    "LOW_MARGIN": "هامش منخفض",
    "CLOSE_ORDER": "إغلاق الطلب",
    "CLOSE_CONFIRM": "هل أنت متأكد من إغلاق هذا الطلب؟",
    "TRADE_ALREADY_CLOSED": "طلبك مغلق بالفعل",
    "INVALID_SIDE": "جانب غير صالح",
    "INVALID_PAIR": "زوج غير صالح",
    "QUANTITY_MINIMUM_ERROR": "يجب أن تكون كمية العقد أكبر من {{AMOUNT}}",
    "QUANTITY_MAXIMUM_ERROR": "يجب أن تكون كمية العقد أقل من {{AMOUNT}}",
    "INSUFFICIENT_TRADE_BALANCE": "بسبب عدم كفاية الرصيد، لا يمكن تنفيذ الطلب",
    "ORDER_PLACE_SUCCESS": "تم تقديم طلبك بنجاح",
    "NO_DATA": "لا توجد بيانات",
    "ORDER_CLOSE_SUCCESS": "تم إغلاق طلبك بنجاح",
    "CLOSED_BY_USER": "مغلق بواسطة المستخدم",
    "LOW_MARGIN": "هامش منخفض",


    "LIVE_EVENTS": "الأحداث المباشرة",
    "NO_LIVE_EVENTS": "لا توجد أحداث مباشرة لعرضها",
    "LIVE_ERROR": "متصفحك لا يدعم علامة الفيديو",

    "HEDGE_FUND": "صندوق التحوط",
    "TOTAL_HEDGE_FUND": "إجمالي قيمة محفظة صندوق التحوط",
    "PORTFOLIO_PERFORMANCE": "أداء المحفظة",
    "PORTFOLIO_ALLOCATION": "توزيع المحفظة",
    "NAME": "اسم",
    "PRICE": "سعر",
    "24_CHANGE": "تغير 24 ساعة",
    "24_HIGH": "أعلى سعر خلال 24 ساعة",
    "24_LOW": "أدنى سعر خلال 24 ساعة",
    "TOTAL": "إجمالي",
    "DAY": "يوم",
    "DAYS": "أيام",
    "WEEK": "أسبوع",
    "MONTH": "شهر",
    "YEAR": "سنة",

    "STAKED_BALANCE": "الرصيد المراهن",
    "ACCOUNT_BALANCE": "رصيد الحساب",
    "TOTAL_PL": "إجمالي الأرباح والخسائر",


    "INSTRUMENTS": "الأدوات",
    "FOREX": "فوركس",
    "TRADING": "تداول",
    "PLATFORM": "منصة",
    "OPEN_ACCOUNT": "فتح حساب",
    "MT5_PLATFORM": "منصة Mt5",
    "WEBTRADER_PLATFORM": "منصة Webtrader",
    "MOBILE_PLATFORM": "منصة الجوال",
    "FX_PAYMENTS": "مدفوعات الفوركس",
    "ACCOUNT_TYPES": "أنواع الحسابات",
    "FOOTER": "العملة التي يتم التعامل بها",
    "FOOTER_CONTENT": "'Staking Review' وعلامات Staking Review التجارية هي ملك لشركة Staking Review. جميع العلامات التجارية الأخرى الظاهرة على هذا الموقع هي ملك لأصحابها الشرعيين.",
    "TERMS_OF_SERVICE": "شروط الخدمة",
    "PRIVACY_POLICY": "سياسة الخصوصية",
    "COPY_RIGHTS": "حقوق الطبع والنشر © {{year}} <a href='/'>{{title}}</a> جميع الحقوق محفوظة",
    "SILVER_ACCOUNT": "حساب فضي",
    "MINI_DEP": "حد أدنى للإيداع",
    "INCLUDES": "يشمل",
    "CRYPTO_CURRENCIES": "العملات المشفرة",
    "SAVING_ACCOUNT": "حساب توفير",
    "DAILY_MARKET_VIEW": "مراجعة السوق اليومية",
    "WEEKLY_MARKET_VIEW": "مراجعة السوق الأسبوعية",
    "WEEKLY_WEBINAR": "ندوة تداول مباشرة أسبوعية",
    "DAILY_POR": "تقرير تقدم المحفظة اليومية",
    "MONTHLY_SESSION": "جلسة شهرية مع محاسب معتمد",
    "FOREX_LEVERAGE": "حتى {{leverage}} رافعة مالية للفوركس",
    "STOCKS_LEVERAGE": "{{leverage}} رافعة مالية للأسهم",
    "MARGIN_LOAN": "حتى {{percentage}} قرض هامش",
    "PERSONAL_PORFOLIO": "مدير محفظة شخصي",
    "ACCESS_TRADING_EVENTS": "الوصول إلى جميع الأحداث التجارية الخاصة",
    "DAILY_NEWS": "أخبار يومية",
    "ACCOUNT_MANAGER": "مدير الحساب",
    "WEEKLY_PORFOLIO": "تقرير تقدم المحفظة الأسبوعي",
    "SELECT_SILVER": "اختيار الفضة",
    "GOLD_ACCOUNT": "حساب ذهبي",
    "INVITATION": "دعوة إلى أحداث تداول خاصة",
    "SILVER_DESCRIPTION2": "العمل مع مدير محفظة",
    "24_HOUR": "مراقبة الحساب على مدار الساعة من قبل مجموعة المحللين المتميزين",
    "SELECT_GOLD": "اختيار الذهب",
    "PLATINUM_ACCOUNT": "حساب بلاتيني",
    "WEEKLY_ANALYST": "جلسة أسبوعية فردية مع محلل السوق",
    "SELECT_PLATINUM": "اختيار البلاتين",
    "DIAMOND_ACCOUNT": "حساب ألماسي",
    "DAILY_ANALYST": "جلسة يومية فردية مع المحلل المتميز",
    "CUSTOMIZED_EDUCATION": "تعليم مخصص",
    "DAILY_MARKET": "إشارات سوق يومية",
    "RESEARCH": "بحث معمق",
    "INVITES_VIP": "دعوات إلى أحداث VIP",
    "SELECT_DIAMOND": "اختيار الألماس",
    "CORPORATE_ACCOUNT": "حساب مؤسسي",
    "DAILY_REVIEW": "مراجعة السوق اليومية والإشارات",
    "UNLIMITED_ACCESS": "وصول غير محدود إلى الوسطاء",
    "PERSONAL_CHIEF": "مدير محفظة رئيسي شخصي",
    "DAILY_ONE": "ندوة تداول مباشرة يومية فردية مع المحلل المتميز",
    "WEALTH_MANAGER": "مدير الثروات",
    "SELECT_CORPORATE": "اختيار المؤسسي"


}











