export default {
  HOMEPAGE_TITLE:
    "Desbloquea tu potencial de ganancias: Maximiza tus retornos con <span>{{StakingReview}}</span>",
  TITLE_CONTENT:
    "Comienza tu viaje de staking en criptomonedas hoy mismo y observa cómo florecen tus inversiones. ¡Es hora de reclamar tu lugar en la revolución criptográfica!",
  HOME_BTN1: "Stake Ahora",
  HOME_BTN2: "Regístrate Ahora",
  COUNT1: "Todos los Participantes",
  COUNT2: "Total Ganado",
  COUNT3: "Cambio de BTC en 24H",
  HEADER1: "Cómo funciona <span>{{StakingReview}}</span>",
  TITLE11: "Stakea tu Criptomoneda",
  CONTENT11:
    "Elige una criptomoneda que soporte staking, adquiérela y transfírela a una plataforma o billetera compatible con staking.",
  TITLE12: "Participa en Staking Review",
  CONTENT12:
    "Tu criptomoneda stakeada ayuda a asegurar la red blockchain y apoya sus operaciones. Tu participación en las actividades de la red te otorga recompensas de staking basadas en el tamaño de tu stake y las reglas de la red.",
  TITLE13: "Gestiona y Monitorea",
  CONTENT13:
    "Revisa regularmente tu rendimiento en staking, rastrea tus recompensas y mantente informado sobre cualquier cambio en la red. Ajusta tu estrategia según sea necesario para aprovechar al máximo tu experiencia de staking.",
  HEADER2: "Gana hasta <span>{{StakingReview}}</span> en tus activos",
  TITLE21: "Ingresos Pasivos con Staking",
  CONTENT21:
    "Explorando el Potencial de Ganar Pasivamente a través del Staking de Criptomonedas",
  TITLE22: "Maximizando los Retornos de Staking",
  CONTENT22:
    "Estrategias para Optimizar tus Ganancias en el Staking de Criptomonedas",
  TITLE23: "Comprendiendo las Recompensas de Staking",
  CONTENT23:
    "Cómo el Staking Genera Beneficios en el Mundo de las Criptomonedas",
  STAKECAL_TITLE: "Mira lo que puedes Ganar <span>{{Staking}}</span>",
  ASSET: "Activo",
  AMOUNT: "Monto",
  AMOUNT_PLACEHOLDER: "Introduce el Monto",
  STAKING_METHOD: "Método de Staking",
  DEPOSIT_TERM: "Término de Depósito",
  FLEXIBLE: "Flexible",
  FIXED: "Fijo",
  STAKE_PERIOD: "En 12 meses tendrás un total de",
  IN: "En",
  YOU_WILL: "tendrás un total de",
  FREQUENTLY_ASKED_QUESTIONS: "Preguntas Frecuentes <span>{{Questions}}</span>",
  REGISTER: "Registrarse",
  LOGIN: "Iniciar Sesión",
  SIGN_UP: "Registrarse",
  SIGN_IN: "Iniciar Sesión",
  FIRST_NAME: "Nombre",
  FIRST_NAME_PLACEHOLDER: "Introduce el Nombre",
  FIRST_NAME_REQUIRED: "El campo del nombre es obligatorio",
  LAST_NAME: "Apellido",
  LAST_NAME_PLACEHOLDER: "Introduce el Apellido",
  LAST_NAME_REQUIRED: "El campo del apellido es obligatorio",
  EMAIL: "Dirección de Correo Electrónico",
  EMAIL_PLACEHOLDER: "Introduce la Dirección de Correo Electrónico",
  EMAIL_REQUIRED: "El campo de correo electrónico es obligatorio",
  EMAIL_INVALID: "Correo electrónico inválido",
  EMAIL_NOT_EXISTS: "El correo electrónico no existe",
  EMAIL_EXISTS: "El correo electrónico ya existe",
  PHONE_NUMBER: "Número de Teléfono",
  PHONE_PLACEHOLDER: "Introduce el Número de Teléfono",
  PHONE_NUMBER_REQUIRED: "El campo del número de teléfono es obligatorio",
  PHONE_NUMBER_INVALID: "Número de teléfono inválido",
  PHONE_NUMBER_EXISTS: "El número de teléfono ya existe",
  PASSWORD: "Contraseña",
  PASSWORD_PLACEHOLDER: "Introduce la Contraseña",
  PASSWORD_REQUIRED: "El campo de la contraseña es obligatorio",
  PASSWORD_INCORRECT: "Contraseña incorrecta",
  PASSWORD_MIN_MAX:
    "La contraseña debe contener al menos una mayúscula, al menos una minúscula, al menos un número, al menos un carácter especial y tener entre 6 y 18 caracteres",
  CONFIRM_PASSWORD: "Confirmar Contraseña",
  CONFIRM_PASSWORD_PLACEHOLDER: "Introduce la Confirmación de Contraseña",
  CONFIRM_PASSWORD_REQUIRED:
    "El campo de confirmación de contraseña es obligatorio",
  CONFIRM_PASSWORD_MISMATCH:
    "La contraseña y la confirmación de contraseña deben ser iguales",
  TERMS_REQUIRED: "El campo de términos y política es obligatorio",
  ALREADY_HAVE_ACCOUNT: "Ya tienes una cuenta",
  "DON'T_HAVE_ACCOUNT": "No tienes una cuenta",
  REGISTER_CHECKBOX:
    "Entiendo <Link to='/terms'>{{value1}}</Link> y <Link1 to='/privacy'>{{value2}}</Link1>",
  OTP: "OTP",
  OTP_PLACEHOLDER: "Introduce el OTP",
  RESENT_OTP: "Reenviar Código de Verificación",
  OTP_VALIDUPTO: "OTP válido hasta",
  OTP_REQUIRED: "El campo OTP es obligatorio",
  OTPSTATUS_REQUIRED: "El estado del OTP es obligatorio",
  INVALID_OTPSTATUS: "Estado de OTP inválido",
  OTP_SENT_SUCCESS:
    "OTP enviado a tu dirección de correo electrónico, por favor introduce un OTP",
  EXPIRED_OTP: "OTP Expirado",
  INVALID_OTP: "OTP Inválido",
  SEC: "Seg",
  REGISTER_SUCCESS: "Tu cuenta ha sido registrada exitosamente. ¡Gracias!",
  SOMETHING_WRONG: "Algo salió mal",
  ERROR_ON_SERVER: "Error en el servidor",
  ERROR_OCCURED: "Error ocurrido",
  EMAIL_UNVERIFIED: "Tu cuenta aún no está activada",
  ACCOUNT_DEACTIVATE:
    "Tu cuenta ha sido desactivada por el administrador, por favor contacta al administrador",
  LOGIN_SUCCESS: "Inicio de sesión exitoso",
  LOGOUT_SUCCESS: "¡Cierre de sesión exitoso!",
  FORGOT_PASSWORD: "Olvidé mi contraseña",
  FORGOT_PASSWORD_CONTENT:
    "El enlace para restablecer la contraseña ha sido enviado a tu dirección de correo electrónico registrada",
  FORGOT_SUCCESS:
    "El enlace de confirmación ha sido enviado a tu correo registrado",
  AUTH_TOKEN_REQUIRED: "El campo del token de autenticación es obligatorio",
  INVALID_TOKEN: "Token inválido",
  RESET_PASSWORD: "Restablecer Contraseña",
  NEW_PASSWORD: "Nueva Contraseña",
  NEW_PASSWORD_PLACEHOLDER: "Introduce Nueva Contraseña",
  NEW_PASSWORD_REQUIRED: "El campo de nueva contraseña es obligatorio",
  NEW_CONFIRM_MISMATCH:
    "La nueva contraseña y la confirmación deben ser iguales",
  LING_EXPIRED: "Tu enlace ha expirado",
  INVALID_LINK: "Enlace inválido",
  USER_NOT_FOUND: "Usuario no encontrado",
  VERIFICATION_SUCCESS:
    "Tu proceso de verificación se completó, ahora puedes cambiar la contraseña",
  RESET_PASSWORD_SUCCESS: "Contraseña actualizada exitosamente",
  CHANGE_PASSWORD: "Cambiar Contraseña",
  OLD_PASSWORD: "Contraseña Anterior",
  OLD_PASSWORD_PLACEHOLDER: "Introduce Contraseña Anterior",
  OLD_PASSWORD_REQUIRED: "El campo de contraseña anterior es obligatorio",
  INCORRECT_OLDPASSWORD: "Contraseña anterior incorrecta",
  PASSWORD_SAME: "La nueva contraseña no puede ser la misma que la antigua",
  CHANGE_PASSWORD_SUCCESS: "Contraseña cambiada exitosamente",
  CHANGE: "Cambiar",
  LAST_LOGIN: "Último inicio de sesión",
  EDIT_PROFILE: "Editar Perfil",
  UPDATE_PROFILE: "Actualizar Perfil",
  PROFILE: "Foto de Perfil (Opcional)",
  INVALID_PROFILE: "Formato de perfil inválido",
  TOO_LARGE: "Demasiado grande",
  PROFILE_RESTRICTION:
    "Tamaño máximo de archivo de carga 2MB (solo Jpeg, Png, Jpg).",
  ADDRESS: "Dirección",
  ADDRESS_PLACEHOLDER: "Introduce Dirección",
  ADDRESS_REQUIRED: "El campo de dirección es obligatorio",
  CITY: "Ciudad",
  CITY_PLACEHOLDER: "Introduce Ciudad",
  CITY_REQUIRED: "El campo de ciudad es obligatorio",
  POSTAL_CODE: "Código Postal / Zip",
  POSTAL_CODE_PLACEHOLDER: "Introduce Código Postal / Zip",
  POSTAL_CODE_REQUIRED: "El campo de código postal es obligatorio",
  COUNTRY: "País",
  COUNTRY_PLACEHOLDER: "Introduce País",
  COUNTRY_REQUIRED: "El campo de país es obligatorio",
  UPLOAD_IMAGE: "¡La imagen subida no puede ser mayor a 2MB!",
  CANCEL: "Cancelar",
  UPDATE: "Actualizar",
  SUBMIT: "Enviar",
  PROFILE_SUCCESS: "Perfil actualizado exitosamente",
  LOGIN_ACTIVITY: "Actividad de Inicio de Sesión",
  DATE: "Fecha",
  BROWSER: "Navegador",
  STATUS: "Estado",
  FAILED: "Fallido",
  SUCCESS: "Éxito",
  MY_ACCOUNT: "Mi Cuenta",
  ACCOUNT_SETTINGS: "Configuración de Cuenta",
  LOGOUT: "Cerrar Sesión",
  NOTIFICATION: "Notificaciones",
  NO_NOTIFICATION: "¡Sin Notificaciones!",
  UPDATE_SUCCESS: "Actualización exitosa",

  SUPPORT_TICKET: "Ticket de Soporte",
  SUBMIT_REQUEST: "Enviar una Solicitud",
  TICKET_FOR: "Ticket Para",
  SELECT_CATEGORY: "Seleccionar Categoría",
  DESCRIPTION: "Descripción",
  ENTER_MESSAGE: "Introduce el Mensaje",
  SUPPORT_DESCRIPTION:
    "Por favor, introduce los detalles de tu solicitud. Un miembro de nuestro personal de soporte responderá lo antes posible.",
  REQUIRED: "Requerido",
  DOCUMENT_UPLOAD: "Subir Documento (Opcional)",
  UPLOAD: "Subir",
  UPLOAD_SIZE_RESTRICT:
    "Tamaño máximo de archivo para subir 1MB (solo Jpeg, Png, Pdf, Jpg).",
  UPLOAD_ERROR: "¡La imagen subida no puede ser mayor a 1MB!",
  MESSAGE_REQUIRED: "El campo del mensaje es obligatorio",
  SUBJECT_REQUIRED: "El campo del asunto es obligatorio",
  UPLOAD_ERROR2:
    "Por favor, elige entre los tipos de archivo conocidos jpg, jpeg, png, pdf o mp4.",
  TICKET_RAISED_SUCCESS: "Ticket de soporte creado exitosamente",
  TICKET_ALREADY_CLOSED: "Ticket ya cerrado",
  MESSAGE_SENT_SUCCESS: "Tu mensaje ha sido enviado exitosamente",
  SUPPORT_TICKET_DETAILS: "Detalles del Ticket de Soporte",
  TICKET_CLOSED_SUCCESS: "Ticket Cerrado Exitosamente",
  TICKET_ID: "ID del Ticket",
  CREATES_ON: "Creado en",
  VIEW: "Ver Archivo",
  SUPPORT_TEAM: "Equipo de Soporte",
  OPEN: "Abierto",
  CLOSED: "Cerrado",
  ALL: "Todos",
  SEARCH: "Buscar",
  CLOSE_TICKET: "Cerrar Ticket",
  SUB: "Sub",
  TICKET_NOT_FOUND: "No se encontraron Tickets",

  WALLET: "Billetera",
  TOTAL_BALANCE: "Saldo Total",
  BALANCE: "Saldo",
  ACTION: "Acción",
  DEPOSIT: "Depositar",
  WITHDRAW: "Retirar",
  EXCHANGE: "Intercambiar",
  EXCHANGE_ACCESS: "Acceso al Intercambio Bloqueado",
  STAKE: "Apostar",
  STAKE_ACCESS: "Acceso a la Apuesta Bloqueado",
  TRADE_ACCESS: "Acceso al Comercio Bloqueado",
  LIVE_EVENT_ACCESS: "Acceso a Eventos en Vivo Bloqueado",
  HEDGE_ACCESS: "Acceso a Fondo de Cobertura Bloqueado",
  HIDE_ZERO_BALANCES: "Ocultar Saldos Cero",

  DEPOSIT_TERMS: "Términos del Depósito",
  MINIMUM_DEPOSIT_AMOUNT: "Monto Mínimo de Depósito: ",
  INVALID_IMAGE: "Imagen Inválida",
  MAINTENANCE_ERROR:
    "¡Mantenimiento en proceso, intente después de algún tiempo!",
  DEPOSIT_DESCRIPTION:
    "Los depósitos menores al monto mínimo no serán aceptados y los reembolsos no están permitidos",
  DEPOSIT_NOTES1:
    "Envíe solo {{CURRENCY_SYMBOL}} a esta dirección de billetera.",
  DEPOSIT_NOTES2:
    "Enviar monedas o tokens distintos a {{CURRENCY_SYMBOL}} a esta billetera puede resultar en la pérdida total de su depósito",
  DEPOSIT_NOTES3:
    "Por favor, cargue una captura de pantalla o un hash de su transacción para que podamos aprobarla",
  DEPOSIT_CARD: "Depositar con Tarjeta",
  DEPOSIT_ADDRESS: "Dirección de Depósito",
  MEMO_ADDRESS: "Dirección de Memo",
  DEPOSIT_IMAGE_RESTRICTION:
    "Tamaño máximo de archivo para cargar 2MB (Jpg, Jpeg, Png, Pdf solamente).",
  DEPOSIT_AMOUNT_ERROR:
    "Por favor, ingrese un monto de depósito mayor o igual a {{AMOUNT}}",
  DEPOSIT_SUCCESS: "Solicitud de depósito enviada exitosamente",
  ASSET_ERROR: "El campo de activo del usuario es obligatorio",
  COPIED: "¡Copiado!",

  WITHDRAW_TERMS: "Términos de Retiro",
  MINIMUM_WITHDRAW_AMOUNT: "El monto mínimo de retiro es: ",
  TERMS1: "Máximo Retiro:",
  TERMS2: "Tarifa de Retiro:",
  RECEIVER_ADDRESS: "Dirección del Receptor",
  RECEIVER_ADDRESS_PLACEHOLDER:
    "Por favor, ingrese la dirección de retiro aquí",
  RECEIVER_ADDRESS_REQUIRED:
    "El campo de dirección del receptor es obligatorio",
  WITHDRAW_AMOUNT: "Monto de Retiro",
  WITHDRAW_AMOUNT_ERROR:
    "Por favor, ingrese un monto de retiro mayor o igual a {{AMOUNT}}",
  MINIMUM_WITHDRAW_ERROR:
    "El monto mínimo de retiro debe ser mayor o igual a {{AMOUNT}}",
  WITHDRAW_MAXAMOUNT_ERROR:
    "El monto de retiro debe ser menor o igual a {{AMOUNT}}",
  WITHDRAW_SUCCESS: "Solicitud de retiro enviada exitosamente",
  INSUFFICIENT_WITHDRAW: "Monto de retiro demasiado bajo",
  WITHDRAW_OTP:
    "Un OTP ha sido enviado exitosamente a su dirección de correo electrónico. Por favor, revise su bandeja de entrada y use el código proporcionado para continuar",

  FROM_CURRENCY: "De Moneda",
  TO_CURRENCY: "A Moneda",
  TO_CURRENCY_REQUIRED: "El campo de moneda de destino es obligatorio",
  SELECT_CURRENCY: "Seleccionar Moneda",
  INVALID_USER: "Usuario inválido",
  INSUFFICIENT_BALANCE: "Saldo insuficiente",
  TRANSFER_SUCCESS: "Transferido exitosamente",

  TRANSACTION: "Transacciones",
  DATE: "Fecha",
  COIN: "Moneda",
  USER_ID: "ID de Usuario",
  TYPE: "Tipo",
  REASON: "Razón",
  PENDING: "Pendiente",
  COMPLETED: "Completado",
  REJECTED: "Rechazado",
  COIN_DEPOSIT: "Depósito de Moneda",
  COIN_WITHDRAW: "Retiro de Moneda",
  COIN_TRANSFER: "Transferencia de Moneda",
  PROFIT: "Beneficio",
  ROWS_PER_PAGE: "Filas por página",
  OF: "de",

  STAKING: "Staking",
  AVAILABLE_BALANCE: "Saldo Disponible",
  AVAILABLE_BALANCE_USDT: "Saldo Disponible USDT",
  APY: "APY",
  MAX: "Máx",
  INTEREST: "Interés",
  MINIMUM_STAKE: "Stake Mínimo",
  MAXIMUM_STAKE: "Stake Máximo",
  WITHDRAWAL_TERM: "Término de Retiro",
  REDEMPTION_PERIOD: "Período de Redención",
  DURATION: "Duración (Días)",
  CONFIRM: "Confirmar",
  STAKING_PERIOD: "Período de Staking",
  LOCKED_FOR: "Bloqueado por ",
  AMOUNT_REQUIRED: "El campo de monto es obligatorio",
  VALID_AMOUNT: "Por favor, ingrese un monto válido",
  "AMOUNT_CAN'T_ZERO": "El monto debe ser mayor que cero",
  MINIMUM_AMOUNT: "El monto mínimo debe ser mayor o igual a {{AMOUNT}}",
  MAXIMUM_AMOUNT: "El monto debe ser menor o igual a {{AMOUNT}}",
  INVALID_STAKING: "Staking inválido",
  INVALID_CURRENCY: "Moneda inválida",
  INVALID_ASSET: "Activo inválido",
  DEACTIVE: "Desactivado",
  NO_RECORD: "Sin registro",
  INSUFFICIENT_ASSET: "No hay suficiente activo en tu saldo",
  STAKING_SUCCESS: "Orden de staking añadida con éxito",
  DURATION_ERROR: "Por favor elige los días de duración",
  ALREADY_CANCELED: "Ya cancelado",
  STAKING_HISTORY: "Historial de Staking",
  TOTAL_EARNED_REDEMPTION: "Total Ganado en Redención",
  INTEREST_PAID: "Interés Pagado",
  ESTIMATED_APY_INTEREST: "Interés APY Estimado",
  ESTIMATED_TOTAL_INTEREST: "Interés Total Estimado",
  ESTIMATED_TOTAL_REDEMPTION: "Redención Total Estimada",
  REMAINING_DAYS: "Días Restantes",
  RELEASE: "Liberar",
  INTEREST_EARNED: "Interés Ganado",
  INTEREST_IN: "Interés en ($)",
  STAKE_TYPE: "Tipo de Stake",
  SUBSCRIPTION: "Suscripción",
  REDEMPTION_HISTORY: "Historial de Redención",
  THERE_NO_RECORD: "No hay registros para mostrar",
  RELEASE_STAKE: "¿Estás seguro de cancelar esta orden?",
  OK: "OK",
  RELEASE_SUCCESS: "Liberado con éxito",

  TRADE: "Comercio",
  PRO_TRADE: "COMERCIO PRO",
  BASIC: "Básico",
  LOADING: "Cargando",
  PNL: "PNL",
  CASH_BALANCE: "Saldo en Efectivo",
  EQUITY: "Patrimonio",
  USED_MARGIN: "Margen Usado",
  AVAILABLE_MARGIN: "Margen Disponible",
  CRYPTO: "Cripto",
  CURRENCIES: "Monedas",
  STOCKS: "Acciones",
  INDICES: "Índices",
  COMMODITIES: "Materias Primas",
  BUY: "Comprar",
  SELL: "Vender",
  QUANTITY: "Cantidad",
  QUANTITY_PLACEHOLDER: "Ingrese Cantidad",
  QUANTITY_REQUIRED: "El campo de cantidad es obligatorio",
  QUANTITY_ERROR: "La cantidad debe ser un valor numérico",
  QUANTITY_GRATER: "La cantidad debe ser mayor que cero",
  SIDE_REQUIRED: "El campo de lado es obligatorio",
  INVALID_SIDE: "Lado inválido",
  PAIR_REQUIRED: "El campo de par es obligatorio",
  TRADE_SIZE: "Tamaño de Comercio",
  PIP_VALUE: "Valor del Pip",
  ORDER_BOOK: "Libro de Órdenes",
  OPEN_POSITIONS: "Posiciones Abiertas",
  CLOSED_POSITIONS: "Posiciones Cerradas",
  CLOSE_ALL_TRADES: "Cerrar Todas las Órdenes",
  PAIR_NAME: "Nombre del Par",
  BUYSELL: "Comprar/Vender",
  ENTRY_PRICE: "Precio de Entrada",
  OPEN_TIME: "Hora de Apertura",
  CLOSE_PRICE: "Precio de Cierre",
  CLOSE_TIME: "Hora de Cierre",
  CURRENT_PRICE: "Precio Actual",
  LEVERAGE: "Apalancamiento",
  PNL_CLOSE: "PNL/Cierre",
  TP: "TP",
  TAKE_PROFIT: "Tomar Beneficios",
  STOP_LOSS: "Detener Pérdidas",
  SL: "SL",
  TP_PRICE: "Tomar Beneficios a Precio",
  SP_PRICE: "Detener Pérdidas a Precio",
  TAKE_PROFIT_PLACEHOLDER: "Ingrese tomar beneficios",
  STOP_LOSS_PLACEHOLDER: "Ingrese detener pérdidas",
  TAKE_PROFIT_REQUIRED: "El campo de tomar beneficios es obligatorio",
  TAKE_PROFIT_GREATER:
    "Tomar beneficios debe ser mayor que el precio del mercado",
  TAKE_PROFIT_LESSER:
    "Tomar beneficios debe ser menor que el precio del mercado",
  STOP_LOSS_REQUIRED: "El campo de detener pérdidas es obligatorio",
  STOP_LOSS_GREATER:
    "Detener pérdidas debe ser menor que el precio del mercado",
  STOP_LOSS_LESSER: "Detener pérdidas debe ser mayor que el precio del mercado",
  POSITION_NOT_FOUND: "Posición no encontrada",
  TAKE_PROFIT_SUCCESS: "Beneficio tomado actualizado con éxito",
  STOP_LOSS_SUCCESS: "Detener pérdidas actualizado con éxito",
  CLOSED_USER: "Cerrado por Usuario",
  LOW_MARGIN: "Margen Bajo",
  CLOSE_ORDER: "Cerrando la orden",
  CLOSE_CONFIRM: "¿Estás seguro de cerrar esta orden?",
  TRADE_ALREADY_CLOSED: "Tu orden ya está cerrada",
  INVALID_SIDE: "Lado inválido",
  INVALID_PAIR: "Par inválido",
  QUANTITY_MINIMUM_ERROR:
    "La cantidad de contrato no debe ser menor que {{AMOUNT}}",
  QUANTITY_MAXIMUM_ERROR:
    "La cantidad de contrato no debe ser mayor que {{AMOUNT}}",
  INSUFFICIENT_TRADE_BALANCE:
    "Debido a saldo insuficiente, no se puede realizar la orden",
  ORDER_PLACE_SUCCESS: "Tu orden se ha realizado con éxito",
  NO_DATA: "Sin datos",
  ORDER_CLOSE_SUCCESS: "Tu orden se ha cerrado con éxito",
  CLOSED_BY_USER: "Cerrado por Usuario",
  LOW_MARGIN: "Margen Bajo",

  LIVE_EVENTS: "Eventos en Vivo",
  NO_LIVE_EVENTS: "No hay eventos en vivo para mostrar",
  LIVE_ERROR: "Tu navegador no es compatible con la etiqueta de video",

  HEDGE_FUND: "Fondo de Cobertura",
  TOTAL_HEDGE_FUND: "Valor Total del Portafolio de Fondo de Cobertura",
  PORTFOLIO_PERFORMANCE: "Desempeño del Portafolio",
  PORTFOLIO_ALLOCATION: "Asignación del Portafolio",
  NAME: "Nombre",
  PRICE: "Precio",
  "24_CHANGE": "Cambio en 24H",
  "24_HIGH": "Máximo en 24H",
  "24_LOW": "Mínimo en 24H",
  TOTAL: "Total",
  DAY: "Día",
  DAYS: "Días",
  WEEK: "Semana",
  MONTH: "Mes",
  YEAR: "Año",

  STAKED_BALANCE: "Saldo Apostado",
  ACCOUNT_BALANCE: "Saldo de la Cuenta",
  TOTAL_PL: "Total P&L",

  INSTRUMENTS: "Instrumentos",
  FOREX: "Forex",
  TRADING: "Trading",
  PLATFORM: "Plataforma",
  OPEN_ACCOUNT: "Abrir Cuenta",
  MT5_PLATFORM: "Plataforma Mt5",
  WEBTRADER_PLATFORM: "Plataforma Webtrader",
  MOBILE_PLATFORM: "Plataforma Móvil",
  FX_PAYMENTS: "Pagos FX",
  ACCOUNT_TYPES: "Tipos de Cuenta",
  FOOTER: "La divisa se basa en",
  FOOTER_CONTENT:
    "'Staking Review' y la familia de marcas de Staking Review son propiedad de Staking Review Corporation. Todas las demás marcas comerciales que aparecen en este sitio web son propiedad de sus respectivos propietarios.",
  TERMS_OF_SERVICE: "Términos de Servicio",
  PRIVACY_POLICY: "Política de Privacidad",
  COPY_RIGHTS:
    "Copyright © {{year}} <a href='/'>{{title}}</a> Todos los derechos reservados",
  SILVER_ACCOUNT: "CUENTA DE PLATA",
  MINI_DEP: "Depósito Mínimo",
  INCLUDES: "Incluye",
  CRYPTO_CURRENCIES: "Criptomonedas",
  SAVING_ACCOUNT: "Cuenta de Ahorros",
  DAILY_MARKET_VIEW: "Revisión diaria del mercado",
  WEEKLY_MARKET_VIEW: "Revisión semanal del mercado",
  WEEKLY_WEBINAR: "Seminario web de trading en vivo semanal",
  DAILY_POR: "Informe diario de progreso del portafolio",
  MONTHLY_SESSION: "Sesión mensual con un contador certificado",
  FOREX_LEVERAGE: "hasta {{leverage}} de apalancamiento para forex",
  STOCKS_LEVERAGE: "{{leverage}} de apalancamiento para acciones",
  MARGIN_LOAN: "Hasta {{percentage}} de préstamo de margen",
  PERSONAL_PORFOLIO: "Gestor de portafolio personal",
  ACCESS_TRADING_EVENTS: "Acceso a todos los eventos de trading especiales",
  DAILY_NEWS: "Noticias diarias",
  ACCOUNT_MANAGER: "Gestor de cuenta",
  WEEKLY_PORFOLIO: "Informe semanal de progreso del portafolio",
  SELECT_SILVER: "Seleccionar Plata",
  GOLD_ACCOUNT: "CUENTA DE ORO",
  INVITATION: "Invitación a eventos de trading especiales",
  SILVER_DESCRIPTION2: "Trabajar con un gestor de portafolio",
  "24_HOUR": "Monitoreo de cuenta 24/7 por el grupo de analistas principal",
  SELECT_GOLD: "Seleccionar Oro",
  PLATINUM_ACCOUNT: "CUENTA DE PLATINO",
  WEEKLY_ANALYST: "Semanal 1 a 1 con analista de mercado",
  SELECT_PLATINUM: "Seleccionar Platino",
  DIAMOND_ACCOUNT: "CUENTA DE DIAMANTE",
  DAILY_ANALYST: "Diaria 1 a 1 con el principal analista",
  CUSTOMIZED_EDUCATION: "Educación Personalizada",
  DAILY_MARKET: "Señales de mercado diarias",
  RESEARCH: "Investigación en profundidad",
  INVITES_VIP: "Invitaciones a eventos VIP",
  SELECT_DIAMOND: "Seleccionar Diamante",
  CORPORATE_ACCOUNT: "CUENTA CORPORATIVA",
  DAILY_REVIEW: "Revisión diaria del mercado y señales",
  UNLIMITED_ACCESS: "Acceso ilimitado a corredores",
  PERSONAL_CHIEF: "Gestor principal de portafolio personal",
  DAILY_ONE:
    "Seminario web de trading en vivo diario 1 a 1 con el principal analista",
  WEALTH_MANAGER: "Gestor de Patrimonios",
  SELECT_CORPORATE: "Seleccionar Corporativo",
};
